export const useCapitalize = (str: string | undefined) => {
  // utility composable to  capitalize any string passed
  return typeof str === "undefined"
    ? ""
    : str
        .toLowerCase()
        .split(" ")
        .map((word) => {
          return word.charAt(0).toUpperCase() + word.slice(1);
        })
        .join(" ");
};
